.surface-profile {
  .surafce-img {
    // width: 50px;
    // height: 50px;
  }
  .star-icon {
    width: 20px;
    height: 20px;
  }
  .seeker-img {
    width: 65px;
    height: 65px;
    // border-radius: 50%;
  }
  .seeker-small-img {
    width: 45px;
    height: 45px;
    // border-radius: 50%;
  }
  .flagg-btn {
    width: 10rem;
  }
}
.business-profile {
  .business-profile-img {
    width: 65px;
    height: 65px;
  }
  .partner-profile-img {
    width: 60px;
    height: 60px;
  }
  .partner-profile-small-img {
    width: 50px;
    height: 50px;
  }
}
.details-modal {
  width: 600px !important;
  .seeker-img {
    width: 70px;
    height: 70px;
  }
  .seeker-small-img {
    width: 65px;
    height: 65px;
    // border-radius: 50%;
  }
}

@media screen and (max-width: 1440px) {
  .surface-profile {
    .surafce-img {
      width: 50px;
      height: 50px;
    }
    .star-icon {
      width: 15px;
      height: 15px;
    }
    .seeker-img {
      width: 50px;
      height: 50px;
      // border-radius: 50%;
    }
    .seeker-small-img {
      width: 35px;
      height: 35px;
      // border-radius: 50%;
    }
    .flagg-btn {
      width: 10rem;
    }
  }
  .business-profile {
    .business-profile-img {
      width: 55px;
      height: 55px;
    }
    .partner-profile-img {
      width: 50px;
      height: 50px;
    }
    .partner-profile-small-img {
      width: 40px;
      height: 40px;
    }
  }
  .details-modal {
    width: 550px !important;
    .seeker-img {
      width: 60px;
      height: 60px;
    }
    .seeker-small-img {
      width: 55px;
      height: 55px;
      // border-radius: 50%;
    }
  }
}
