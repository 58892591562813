.menu-comp {
  .menu-icon {
    padding: 0.5rem;
  }
  .menu-item {
    padding: "2px 20px";
  }
}

@media screen and (max-width: 1440px) {
  .menu-comp {
    .menu-icon {
      padding: 0.2rem;
    }
    .menu-item {
      padding: "2px 3px";
    }
  }
}
