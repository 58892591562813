.dashboard {
  .filter-text {
    font-size: 0.875rem;
    background-color: #f7f8fa;
    border-radius: 5px 0px 0px 5px;
    padding: 5px 15px 5px 15px;
  }
  .revenue-chart-legend {
    font-size: 0.875rem;
    // padding-left: 0.5rem;
    // text-start text-sm ps-2
  }
  .revenue-chart-filter {
    font-size: 1rem;
    padding: 0.25rem;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 1440px) {
  .dashboard {
    .filter-text {
      font-size: 0.7rem;
      padding: 5px 7px 5px 7px;
    }
    .revenue-chart-legend {
      font-size: 0.7rem;
    }
    .revenue-chart-filter {
      font-size: 0.8rem;
      padding: 0.1rem;
    }
  }
}
