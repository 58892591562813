.account-profile {
  .profile-image {
    width: 100px;
    height: 100px;
    background-color: #e2dafd;
    border-radius: 50%;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .profile-details {
    font-size: 1rem;
  }
  .profile-static {
    padding: 0.75rem;
    h3 {
      font-size: 0.875rem;
    }
    h2 {
      font-size: 1.125rem;
    }
  }
  .chart-content {
    width: 40%;
    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.25rem;
      span {
        font-size: 2.25rem;
      }
    }
  }
  .business-card {
    .title {
      font-size: 1.5rem;
      // font-weight: 700;
      // margin-bottom: 0.5rem;
    }
    .tag {
      padding: 0.4rem;
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
  }
  .flagg-btn {
    width: 10rem;
  }
}

// .add-account-modal {
//   .responsive-width {
//     max-width: 900px !important;
//   }
// }

.message-box {
  position: absolute;
  // bottom: messageModalCollapse ? "-530px" : "0px",
  left: 70%;
  .message-modal {
    width: 400px;
    height: 600px;
    background-color: #f9dd7b;
  }
}

@media screen and (max-width: 1440px) {
  .account-profile {
    .profile-image {
      width: 80px;
      height: 80px;
      background-color: #e2dafd;
      border-radius: 50%;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .profile-details {
      font-size: 0.875rem;
    }
    .profile-static {
      padding: 0.5rem;
      h3 {
        font-size: 0.875rem;
      }
      h2 {
        font-size: 1rem;
      }
    }
    .chart-content {
      h2 {
        font-size: 1rem;
      }
      h3 {
        font-size: 0.75rem;
        span {
          font-size: 1.5rem;
        }
      }
    }
    .business-card {
      .title {
        font-size: 1.2em;
        // font-weight: 700;
        // margin-bottom: 0.5rem;
      }
      .tag {
        padding: 0.3rem;
        font-size: 0.875rem;
      }
    }
    .flagg-btn {
      width: 10rem;
    }
  }

  // .add-account-modal {
  //   .responsive-width {
  //     max-width: 900px !important;
  //   }
  // }

  .message-box {
    position: absolute;
    // bottom: messageModalCollapse ? "-530px" : "0px",
    left: 65%;
    .message-modal {
      width: 300px;
      height: 500px;
      background-color: #f9dd7b;
    }
  }
}
