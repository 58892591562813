.review {
  .review-des {
    font-size: 1rem;
  }
  .action-btn {
    padding: "6px 30px";
  }
}

@media screen and (max-width: 1440px) {
  .review {
    .review-des {
      font-size: 0.875rem;
    }
    .action-btn {
      padding: "6px 10px";
    }
  }
}
