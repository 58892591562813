.setting-wraper {
  .manage-access-wraper {
    .manege-access-profile {
      width: 80px;
      height: 80px;
    }
    .user-privileges {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;
    }
  }
  .app-article-wraper {
    .app-article-img {
      width: 130px;
      height: 130px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .setting-wraper {
    .manage-access-wraper {
      .manege-access-profile {
        width: 70px;
        height: 70px;
      }
      .user-privileges {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3;
      }
    }
  }
}
