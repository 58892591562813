.reservation-profile {
  .reservation-img {
    width: 70px;
    height: 70px;
  }
  .reservation-small-img {
    width: 60px;
    height: 60px;
  }
  .chat-profile-img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .reservation-profile {
    .reservation-img {
      width: 60px;
      height: 60px;
    }
    .reservation-small-img {
      width: 50px;
      height: 50px;
    }
    .chat-profile-img {
      width: 30px;
      height: 30px;
    }
  }
}
